<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<h3 class="mt-3 ml-6">MOVIMIENTOS DE PARIHUELA</h3>
					<v-spacer> </v-spacer>
					<v-btn
						@click="$emit('close')"
						class="mt-3 mr-3"
						small
						fab
						color="error"
						><i style="font-size: 16px" class="fas fa-times"></i
					></v-btn>
				</v-row>

				<v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>

				<v-row>
<!-- 					<v-col cols="3" class="pt-0 mt-4" >
						<b>1 Ingreso: {{EntryInitial}}</b>
					</v-col> -->
					<v-col cols="3" class="pt-0 mt-4" >
						<b>Tamaño/Estado: {{CategoryName}}</b>
					</v-col>
					<v-col cols="3" class="pt-0 mt-4" >
						<b>Peso: {{Weight}}</b>
					</v-col>

					<v-col cols="3" class="pt-0 mt-4" >
						<b>Tipo Cultivo: {{TypeCropName}}</b>
					</v-col>

					<v-col cols="3" class="pt-0 mt-4" >
						<b>Cultivo: {{TypeCultiveName}}</b>
					</v-col>

					<v-col cols="3" class="pt-0 mt-4" >
						<b>Variedad: {{VrtName}}</b>
					</v-col>
				</v-row>

				<v-row>
					
					<v-col cols="12" class="pt-0">
						<!-- <v-list> -->
							<!-- <v-list-item> -->
								<v-data-table
                                    dense
                                    :headers="headers"
                                    :items="itemMovimients"
                                    :items-per-page="-1"
                                    hide-default-footer
									disable-sort
                                >

                                </v-data-table>
							<!-- </v-list-item> -->

						<!-- </v-list> -->
					</v-col>
					<v-col cols="12" class="pt-0">
						<!-- <CardLineChart :text="'N° de dias'" :objLineX="objLineX" :objLineY="objLineY"></CardLineChart> -->
					</v-col>

				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import CardLineChart from '@/components/Utils/Cards/CardLineChart' ;
	export default {
		props: {
            itemMovimients: null
		},
		components: { CardLineChart},
		data() {
			return {
                headers: [

                    {text: "Ambiente", value: "FtmDescription", width: 130},
                    {text: "Estado de Maduración", value: "MtsDescription", width: 100},
                    {text: "Inicio", value: "MtpDateBegin", width: 120},
                    {text: "Fin", value: "MtpDateEnd", width: 120},
					{text: "Dias", value: "MtpDayNewDecimal", width: 100, align: "center"},
                    {text: "HH:MM", value: "MtpMinuteNew", width: 100, align: "center"},
                    // {text: "Tipo Cultivo", value: "TypeCropName", width: 100},
                    // {text: "Cultivo", value: "TypeCultiveName", width: 100},
                    // {text: "Variedad", value: "VrtName", width: 100},
                ],

				EntryInitial: "",
				CategoryName: "",
				TypeCropName: "",
				TypeCultiveName: "",
				VrtName: "",
				Weight: 0,

				objLineX: [],
				objLineY: []

			};
		},

		created() {
            console.log("itemMovimients",this.itemMovimients);

			if(this.itemMovimients.length > 0) {
				this.EntryInitial = this.itemMovimients[0].LlpDateEntryTunnelInitial
				this.CategoryName = this.itemMovimients[0].TypeFrozenCategoryName
				this.Weight = this.itemMovimients[0].LlpWeight
				this.TypeCropName = this.itemMovimients[0].TypeCropName
				this.TypeCultiveName = this.itemMovimients[0].TypeCultiveName
				this.VrtName = this.itemMovimients[0].VrtName

				this.itemMovimients.forEach(element => {

					if(element.MtpDateEnd == null)
					{						
						element.MtpDateEnd = this.$moment().format("YYYY-MM-DD HH:mm");
					}
					if(element.MtpDateEnd !== null){
						element.MtpSecondsNew = 
								this.$fun.isDifferenceMinutes(
									element.MtpDateBegin,
									element.MtpDateEnd,
									"seconds"
								) * -1;

						element.MtpMinuteNew = this.$fun.secondsToString(element.MtpSecondsNew)
						
					}

					this.objLineX.push(element.FtmDescription)
					this.objLineY.push(element.MtpDayNew)
					
					
				});

				console.log("lineaaaaaaa x",this.objLineX, this.objLineY);

				
			}
		},

		methods: {


		},
	};
</script>

<style>
</style>