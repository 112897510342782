import Service from '../Service';

const resource = "changetunnelmaturation/";

export default {
    saveTunnel(IDMaturation, LlpID, IDPallet, type, requestID, TypeStateMp, TypeCultive, VrtID) {
        return Service.post(resource + "savetunnel",null, {
            params: {   
                        IDMaturation: IDMaturation
                        ,requestID: requestID                         
                        ,IDPallet: IDPallet
                        ,type: type
                        ,LlpID: LlpID
                        ,TypeStateMp: TypeStateMp
                        ,TypeCultive: TypeCultive
                        ,VrtID: VrtID
                    },
        });
    },

    saveTunnelGroup(objs, IDPallet, type, requestID, TypeStateMp) {
        return Service.post(resource + "savetunnelgroup", objs, {
            params: {   
                IDPallet: IDPallet
                ,type: type          
                ,requestID: requestID  
                ,TypeStateMp: TypeStateMp               
            },
        });
    },

    saveGroupAmbient(objs, IDPallet, type, requestID, TypeStateMp) {
        return Service.post(resource + "savetunnelgroupambient",objs, {
            params: {   
                IDPallet: IDPallet
                ,type: type          
                ,requestID: requestID  
                ,TypeStateMp: TypeStateMp               
            },
        });
    },

    saveGroupCalifornian(objs, IDPallet, type, requestID, TypeStateMp) {
        return Service.post(resource + "savetunnelgroupcalifornian",objs, {
            params: {   
                IDPallet: IDPallet
                ,type: type          
                ,requestID: requestID  
                ,TypeStateMp: TypeStateMp               
            },
        });
    },

    saveGroupChamber(objs, IDPallet, type, requestID, TypeStateMp) {
        return Service.post(resource + "savetunnelgroupchamber",objs, {
            params: {   
                IDPallet: IDPallet
                ,type: type          
                ,requestID: requestID  
                ,TypeStateMp: TypeStateMp               
            },
        });
    },
};
