<template>
    <div>
        <v-card>            
            <v-container>
                <v-row>
                    <h2 class="mt-3 ml-6">{{ dataTunnelDescription }}</h2>
                    <v-spacer> </v-spacer>
					<v-btn
						@click="$emit('close')"
						class="mt-3 mr-3"
						small
						fab
						color="error"
						><i style="font-size: 16px" class="fas fa-times"></i
					></v-btn>
                </v-row>
                <v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>
                <v-row>
                    <v-col cols="12" lg="12" md="12">
                        <v-tabs v-model="currentItem" bg-color="primary">
                            <v-tab value="PTunnel">Pallets en Tunnel</v-tab>
                            <v-tab value="PCaliforniano">Pallets en Californiano</v-tab>
                            <v-tab value="PPlataforma">Pallets en Plataforma</v-tab>
                            <v-tab value="PCamara">Pallest en Camara</v-tab>
                        </v-tabs>                        
                        <v-tabs-items v-model="currentItem">
                            <v-tab-item :value="PTunnel"> 
                                <v-row justify="center" style="margin:auto">
                                    <v-col 
                                        cols="12"
                                        >
                                        <s-select                                                
                                            :items="itemsTunnel"
                                            item-value="FtmID"
                                            item-text="FtmDescription"
                                            label="Seleccionar Tunnel"
                                            return-object   
                                            autocomplete                                   
                                            v-model="objTunnel"
                                            @input="LimpiarOtrosObjetos($event)"
                                            :slotTmp="true"
                                             >
                                             
                                             <template v-slot:item="data">
                                                {{ data.item.FtmDescription }}
                                                
                                                <v-chip
                                                    small
                                                    class="ma-2"
                                                    variant="outlined"
                                                    :color="data.item.inTunnelStateColor"
                                                    ><b>{{
                                                        data.item.inParihuelaUsage
                                                    }}</b>
                                                </v-chip>

                                                <v-chip
                                                    small
                                                    :color="data.item.inTunnelStateColor"
                                                    ><b>{{
                                                        data.item.inTunnelStateName
                                                    }}</b>
                                                </v-chip>
                                             </template>
                                        </s-select>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item :value="PCaliforniano"> 
                                <v-row justify="center" style="margin:auto">
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12">
                                        <s-select                                                
                                            :items="itemsCalifornian"
                                            item-value="ClfID"
                                            item-text="ClfName"
                                            label="Seleccionar Californiano"
                                            autocomplete
                                            return-object
                                            v-model="objCalifornian"
                                            @input="LimpiarOtrosObjetos($event)"
                                            :slotTmp="true"
                                            >

                                            <template v-slot:item="data">
                                                {{ data.item.ClfName }}
                                                
                                                <v-chip
                                                    small
                                                    class="ma-2"
                                                    variant="outlined"
                                                    :color="data.item.inCalifornianStateColor"
                                                    ><b>{{
                                                        data.item.inParihuelaUsage
                                                    }}</b>
                                                </v-chip>

                                                <v-chip
                                                    small
                                                    :color="data.item.inCalifornianStateColor"
                                                    ><b>{{
                                                        data.item.inCalifornianStateName
                                                    }}</b>
                                                </v-chip>
                                             </template>
                                        </s-select>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item :value="PPlataforma"> 
                                <v-row justify="center" style="margin:auto">
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12">
                                        <s-select                                                
                                            :items="itemsEnvironment"
                                            item-value="MamID"
                                            item-text="MamName"
                                            label="Seleccionar Plataforma"
                                            autocomplete
                                            return-object
                                            v-model="objplatform"
                                            @input="LimpiarOtrosObjetos($event)"
                                            :slotTmp="true">
                                            <template v-slot:item="data">
                                                {{ data.item.MamName }}
                                                
                                                <v-chip
                                                    small
                                                    class="ma-2"
                                                    variant="outlined"
                                                    :color="data.item.inTunnelStateColor"
                                                    ><b>{{
                                                        data.item.inParihuelaUsage
                                                    }}</b>
                                                </v-chip>

                                                <v-chip
                                                    small
                                                    :color="data.item.inTunnelStateColor"
                                                    ><b>{{
                                                        data.item.inTunnelStateName
                                                    }}</b>
                                                </v-chip>
                                             </template>
                                        </s-select>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item :value="PCamara"> 
                                <v-row justify="center" style="margin:auto">
                                    <v-col align="center"
                                        cols="12"
                                        lg="12"
                                        md="12">
                                        <s-select                                                
                                            :items="itemsChamber"                                            
                                            item-value="CchID"
                                            item-text="CchName"
                                            label="Seleccionar Cámara"
                                            autocomplete
                                            return-object
                                            v-model="objChamber"                                            
                                            @input="LimpiarOtrosObjetos($event)"
                                            :slotTmp="true">
                                            <template v-slot:item="data">
                                                {{ data.item.CchName }}
                                                
                                                <v-chip
                                                    small
                                                    class="ma-2"
                                                    variant="outlined"
                                                    :color="data.item.inTunnelStateColor"
                                                    ><b>{{
                                                        data.item.inParihuelaUsage
                                                    }}</b>
                                                </v-chip>

                                                <v-chip
                                                    small
                                                    :color="data.item.inTunnelStateColor"
                                                    ><b>{{
                                                        data.item.inTunnelStateName
                                                    }}</b>
                                                </v-chip>
                                             </template>
                                        </s-select>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>                        
                    </v-col>
                </v-row>
                <v-row justify="center" v-if="objTunnel !== null">
                    <v-col cols="3" v-if="objTunnel.inParihuelaUsage == 0 || objTunnel.MtsID == null">
                        <s-select  
                            :items="itemsStateTunnel"
							item-value="MtsID"
							item-text="MtsDescription"
							label="Estado"
							v-model="TypeStateMp">
                        </s-select>
                    </v-col>
                </v-row>
                <v-row>
					<v-col class="pb-5">
						<v-divider visible="hidden"></v-divider>                       
					</v-col>
				</v-row>
                <v-row>
                    <v-col cols="12" md="4" lg="3" class="mt-2 pb-0">
                        <v-btn
                            small
                            elevation="0"                            
                            dark
                            color="success"
                            @click="save()"
                            
                            >
                            Cambiar Tunel
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
					<v-col class="pb-5">
						<v-divider></v-divider>                       
					</v-col>
				</v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import _SChangeMaduration from "@/services/FrozenProduction/FrzChangeMadurationService";
import sTunnelMaduration from "../../../services/FrozenProduction/TunnelMadurationService";
import _sMaturationTunnelPallet from "@/services/FrozenProduction/MaturationTunnelPalletService";
export default {
    props:{
        dataTunnelDescription: null,
        PTunnel: "",
        PCamara: "",
        PPlataforma: "", 
        PCaliforniano: "",
        PTunnel: "",
        itemsSelected: [],
        VrtID: "",
        TypeCultive: "",
    },
    data(){
        return {
            currentItem: "tab-Funciones",
            itemsTunnel: [],
            itemsChamber: [],
            itemsEnvironment: [],
            itemsCalifornian: [],
            objTunnel: null,
            objCalifornian: null,
            objChamber: null,
            objplatform: null,
            TypeStateMp: 0,  
            type: 0,
            IDPallet: 0
        }
    },
    created()
    {
        
    },
    mounted()
    {
        sTunnelMaduration.list({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.FtmCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.FtmCapacity)- parseInt(element.inParihuelaUsage)
							
							
							//Capacidad llena
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 50 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 50){
								element.inColor = 'success'
							}
	
						});

						this.itemsTunnel = r.data;		
                        
					}
					
				});
        
            sTunnelMaduration.listChamber({}, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                   
                    // r.data.forEach(element => {
                    //     element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.CchCapacity)
                    //     element.ParihuelaAvailableUnit =   parseInt(element.CchCapacity)- parseInt(element.inParihuelaUsage)
                            
                    // });
                    this.itemsChamber = r.data;	
                    
                }
            });
        
            sTunnelMaduration.listCalifornian({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.ClfCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.ClfCapacity)- parseInt(element.inParihuelaUsage)
							
							
							//Capacidad llena
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 50 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 50){
								element.inColor = 'success'
							}
	
						});

						this.itemsCalifornian = r.data;
                        
					}
					
				});
        
            sTunnelMaduration.listEnviroment({}, this.$fun.getUserID()).then(r => {
                if(r.status == 200){

                    r.data.forEach(element => {
                        element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.MamCapacity)
                        element.ParihuelaAvailableUnit =   parseInt(element.MamCapacity)- parseInt(element.inParihuelaUsage)
                        
                        
                        //Capacidad llena
                        if(element.ParihuelaUsagePorcentaje == 100){
                            element.inColor = 'error'
                        }

                        //Tunnel por llenarse
                        if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
                            element.inColor = 'warning'
                        }

                        if(element.ParihuelaUsagePorcentaje > 50 && element.ParihuelaUsagePorcentaje <= 79){
                            element.inColor = 'info'
                        }

                        if(element.ParihuelaUsagePorcentaje <= 50){
                            element.inColor = 'success'
                        }

                    });

                    this.itemsEnvironment = r.data;                    

                }
                
            });
        
            _sMaturationTunnelPallet.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateTunnel = r.data    
                        this.itemsStateTunnel.splice(3,1);                   
					}
				})
        },
        methods: {
            closeDetail() {
                this.$emit("closeDetail");
             },  
            save(){

                
                if(this.objTunnel)
                {   
                    this.type = 1;   
                    this.IDPallet = this.objTunnel.FtmID;   
                    console.log('this.TypeStateMp', this.TypeStateMp, this.IDPallet);
                    this.$fun.alert("¿Deseas mover los pallets, Deseas continuar ?", "question")
                    .then(r => {
                        if(r.value){
                            _SChangeMaduration
                            .saveTunnelGroup(this.itemsSelected, this.IDPallet, this.type, this.$fun.getUserID(), this.TypeStateMp)
                            .then(resp => {
                                if(resp.status == 200)
                                {
                                    this.precessing = false;
                                    this.$fun.alert("Registrados correctamente", "success");
                                    this.closeDetail();
                                }else{
                                    this.$fun.alert("Parihuela ", "error");
                                }
                            });
                        }
                    })
                
                }
                else if(this.objCalifornian)
                {                   
                    this.type = 2;
                    this.IDPallet = this.objCalifornian.ClfID;  

                    this.$fun.alert("¿Deseas mover los pallets, Deseas continuar ?", "question")
                    .then(r => {
                        if(r.value){
                            _SChangeMaduration
                            .saveTunnelGroup(this.itemsSelected, this.IDPallet, this.type, this.$fun.getUserID(), this.objCalifornian.MtsID)
                            .then(resp => {
                                if(resp.status == 200)
                                {
                                    this.precessing = false;
                                    this.$fun.alert("Parihuela ingresada correctamente", "success");
                                    this.closeDetail();
                                }else{
                                    this.$fun.alert("Parihuela ", "error");
                                }
                            });
                        }
                    })
                }
                else if(this.objChamber)
                {
                    this.type = 3;
                    this.IDPallet = this.objChamber.CchID;  

                    this.$fun.alert("¿Deseas mover los pallets, Deseas continuar ?", "question")
                    .then(r => {
                        if(r.value){
                            _SChangeMaduration
                            .saveTunnelGroup(this.itemsSelected, this.IDPallet, this.type, this.$fun.getUserID(), this.objChamber.MtsID)
                            .then(resp => {
                                if(resp.status == 200)
                                {
                                    this.precessing = false;
                                    this.$fun.alert("Registrados correctamente", "success");
                                    this.closeDetail();
                                }else{
                                    this.$fun.alert("Parihuela ", "error");
                                }
                            });
                        }
                    })
                }
                else if(this.objplatform)
                { 
                    this.type = 4;
                    this.IDPallet = this.objplatform.MamID;  
                    
                    this.$fun.alert("¿Deseas mover los pallets, Deseas continuar ?", "question")
                    .then(r => {
                        if(r.value){
                            _SChangeMaduration
                            .saveTunnelGroup(this.itemsSelected, this.IDPallet, this.type, this.$fun.getUserID(), this.objplatform.MtsID)
                            .then(resp => {
                                if(resp.status == 200)
                                {
                                    this.precessing = false;
                                    this.$fun.alert("Registrados correctamente", "success");
                                    this.closeDetail();
                                }else{
                                    this.$fun.alert("Parihuela ", "error");
                                }
                            });
                        }
                    })
                }
            },
            LimpiarOtrosObjetos(item){
                
                if(item == this.objCalifornian)
                {
                    this.objCalifornian = item;
                    this.objTunnel = null;
                    this.objChamber = null;
                    this.objplatform = null;
                }
                else if(item == this.objTunnel)
                {
                    this.objCalifornian = null;
                    this.objTunnel = item;
                    this.objChamber = null;
                    this.objplatform = null;

                    
                }
                else if(item == this.objChamber)
                {
                    this.objCalifornian = null;
                    this.objTunnel = null;
                    this.objChamber = item;
                    this.objplatform = null;
                }
                else if(item == this.objplatform)
                {
                    this.objCalifornian = null;
                    this.objTunnel = null;
                    this.objChamber = null;
                    this.objplatform = item;
                }

            },
        }
    }

</script>
